import { JSX } from "preact";

type ArrowTypes = {
    classNames?: string,
    onClick?: JSX.MouseEventHandler<HTMLButtonElement>,
    link: string,
}

export const CustomCarouselArrow = (props: ArrowTypes): JSX.Element => {
    const { classNames, onClick, link } = props;
    return (
      <button
        onClick={onClick}
        className={`btn-white absolute top-1/2 z-50 size-max bg-bright-white px-6 pb-3.5 pt-3 ${classNames}`}
      >
        <img
          src={link}
          alt="arrow icon"
        />
      </button>
    );
}
 